import React from 'react';
import styled from 'styled-components';
import {
  Layout,
  Map,
  ContactCard,
  ContactForm,
  Container,
} from '../components';

// Constants
import { media } from '../constants/theme';

// Data
import pageContext from '../data/pages/contact.json';

const StyledFlexBox = styled.div`
  @media ${media.notMobile} {
    display: flex;
  }

  > div:first-child {
    margin-bottom: 4rem;
    
    @media ${media.notMobile} {
      margin-right: 6rem;
      flex-shrink: 0;
    }
  }
`;

const Contact = ({ data }) => {
  const contact = data.site.siteMetadata;

  return (
    <Layout pageContext={pageContext}>
      <Container maxWidth="840px">
        <StyledFlexBox>
          <div>
            <ContactCard contact={contact} />
          </div>
          <div>
            <ContactForm contact={contact} />
          </div>
        </StyledFlexBox>
      </Container>
      <div style={{ height: '50vh' }}>
        <Map
          apiKey="AIzaSyCR4lpuTYfQn-EWkjyxYDSj3m_BCbO-TOQ"
          center={{ lat: 49.1728135, lng: -123.1361477 }}
        />
      </div>
    </Layout>
  );
};

export default Contact;

export const query = graphql`
  query {
    site {
      siteMetadata {
        address
        city
        province
        postalCode
        phone
        email
      }
    }
  }
`;
